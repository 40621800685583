import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { deviceMaxWidth } from '~/src/styles/breakpoints';

import {
  ArrowLink,
  HomepageSection,
  SectionDescription,
  SectionHead,
  SectionHeaderWrapper,
  SectionPreHead,
} from './styles';
import { routes } from '~/src/utils/routing';
import LocalizedLink from '~/src/components/LocalizedLink';

const Mission = () => (
  <Wrapper>
    <SectionHeaderWrapper>
      <SectionPreHead>
        <Trans id="mainPage.campiriMission.sectionPreHeader" message="Campiri mise" />
      </SectionPreHead>
      <SectionHead>
        <Trans
          id="mainPage.campiriMission.sectionHead"
          message="Jsme online půjčovna karavanů, která myslí na vás i na planetu."
        />
      </SectionHead>
      <SectionDescription>
        <Trans
          id="mainPage.campiriMission.sectionDescription"
          message="Campiri není jen o půjčování karavanů, ale moderním svobodném životním stylu a cestování s důrazem na
                  udržitelnost a šetrnost vůči životnímu prostředí."
        />
      </SectionDescription>
    </SectionHeaderWrapper>
    <MissionBoxes>
      <MissionBoxesItem>
        <i className="icon icon-location-pin" />
        <MissionBoxesItemDescription>
          <Trans
            id="mainPage.campiriMission.box1"
            message="Nejširší nabídka karavanů, obytných vozů a campervanů v ČR."
          />
        </MissionBoxesItemDescription>
      </MissionBoxesItem>
      <MissionBoxesItem>
        <i className="icon icon-question" />
        <MissionBoxesItemDescription>
          <Trans id="mainPage.campiriMission.box2.needAdvice" message="Nevíte si s něčím rady?" />
          <LocalizedLink href={routes.faq} hash="#contact" passHref={true}>
            <a>
              <MissionBoxesItemDescriptionLink as="span">
                <Trans id="mainPage.campiriMission.box2.hereForYou" message="Jsme tu pro vás." />
              </MissionBoxesItemDescriptionLink>
            </a>
          </LocalizedLink>
        </MissionBoxesItemDescription>
      </MissionBoxesItem>
      <MissionBoxesItem>
        <i className="icon icon-check" />
        <MissionBoxesItemDescription>
          <Trans id="mainPage.campiriMission.box3" message="1 % z obratu na podporu ekologických projektů." />
        </MissionBoxesItemDescription>
      </MissionBoxesItem>
      <MissionBoxesItem>
        <i className="icon icon-eco" />
        <MissionBoxesItemDescription>
          <Trans id="mainPage.campiriMission.box4" message="Možnost filtrovat vozy podle ekologické zátěže." />
        </MissionBoxesItemDescription>
      </MissionBoxesItem>
    </MissionBoxes>
    <LocalizedLink href={routes.mission} passHref={true}>
      <ArrowLink>
        <Trans id="mainPage.campiriMission.button" message="Více o naší misi" />
        <i className="icon-action-forward" />
      </ArrowLink>
    </LocalizedLink>
  </Wrapper>
);

const Wrapper = styled(HomepageSection)`
  background-image: url('/static/images/vectors/fire.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 40px) 120px;
  border-bottom: 1px solid var(--color-beige);
`;

const MissionBoxes = styled.div`
  display: flex;
  width: 100%;
  @media ${deviceMaxWidth.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 600px;
    align-items: flex-start;
  }
`;

const MissionBoxesItem = styled.div`
  flex-grow: 1;
  height: auto;
  min-width: 25.7692308%;
  padding-right: 20px;

  .icon {
    font-size: 2rem;
    margin-bottom: 12px;
    display: inline-block;
    @media ${deviceMaxWidth.phone} {
      font-size: 1.5rem;
    }
    &:before {
      color: #998d8c;
    }
  }

  @media ${deviceMaxWidth.tablet} {
    display: flex;
    flex-basis: calc(50% - 10px);
    justify-content: center;
    flex-direction: column;
  }
`;

const MissionBoxesItemDescription = styled.p`
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  max-width: 235px;
  letter-spacing: normal;
  color: #1f2244;
  margin-bottom: 48px;
  @media ${deviceMaxWidth.tablet} {
    margin-bottom: 33px;
    font-size: 14px;
    line-height: 24px;
  }
`;

const MissionBoxesItemDescriptionLink = styled(MissionBoxesItemDescription)`
  text-decoration: underline;
  display: block;
`;

export default Mission;
