import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import axios from 'axios';
import { NextPage } from 'next';

import dynamic from 'next/dynamic';
import Layout from '~/src/components/layout/Layout';
import HowToStart from '~/src/components/homepage/HowToStart';
import Categories from '~/src/components/homepage/Categories';
import Mission from '~/src/components/homepage/Mission';
import Favorites from '~/src/components/homepage/Favorites';
import { PageWrapper } from '~/src/styles/common';
import { setDateToStoreFromQueryHook } from '~/src/utils/query';

import SEO from '~/src/components/SEO';
import GoogleReviewsHP from '~/src/components/homepage/GoogleReviewsHP';
import { getFeaturedCars } from '~/src/api/cars';
import ChangeLanguageBar from '~/src/components/layout/ChangeLanguageBar';
import { useDisplayChangeLanguageBar } from '~/src/components/layout/useDisplayChangeLanguageBar';
import { languageSelector } from '~/src/redux/selectors/settings';
import { Country, Language } from '~/src/utils/routing';
import { HomePageHeader } from '~/src/components/pageHeader/homepage/HomePageHeader';
import { ListingSearchQueryResult } from '~/libs/api/models/listing-search-query-result';

import { useTypedSelector } from '~/src/redux/store';
import { useCodebase } from 'features/hooks/useCodebase';
import FreewayPage from '~/src/freeway/pages/homepage/page';

const WhatsappWidget = dynamic(() => import('~/src/components/WhatsappWidget'));
const DynamicPromoBlock = dynamic(() =>
  import('~/src/components/homepage/promoBlocks/PromoBlocks').then((module) => module.PromoBlocks)
);

interface IndexProps {
  featuredCars: Array<ListingSearchQueryResult>;
}

declare global {
  interface Window {
    maps: any;
    analytics: any;
    dispatch: any;
    ga: any;
  }
}

const Index: NextPage<IndexProps> = ({ featuredCars }: IndexProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  setDateToStoreFromQueryHook(router, dispatch);
  const { visible, newLanguage, closeLanguageBar } = useDisplayChangeLanguageBar();
  const language = useSelector(languageSelector);
  const country = useTypedSelector((state) => state.settings.country);

  const isFreeway = useCodebase('fwc');

  if (isFreeway)
    return (
      <Layout scrollPosition={true} showSpecialOfferFWC={true}>
        <SEO
          title={t({
            id: 'seo.fwc.title.index',
            message: 'Book your Freedom',
          })}
          titleSuffix={true}
          description={t({
            id: 'seo.fwc.description.index',
            message:
              'Rent Camper and RVs with Freeway Camper: ✅ from 69€/Night ✅ VW Bulli ✅ RVs ✅ Campervans ➡️Book your Freedom now!',
          })}
          ogDescription={t({
            id: 'seo.fwc.description.index',
            message:
              'Rent Camper and RVs with Freeway Camper: ✅ from 69€/Night ✅ VW Bulli ✅ RVs ✅ Campervans ➡️Book your Freedom now!',
          })}
        />
        <FreewayPage />
      </Layout>
    );

  return (
    <>
      <Layout
        scrollPosition={true}
        changeLanguageBar={{
          element: (
            <ChangeLanguageBar visible={visible} newLanguage={newLanguage} closeLanguageBar={closeLanguageBar} />
          ),
          isVisible: visible,
        }}
      >
        <SEO
          title={i18n._(t({ id: 'seo.index' }))}
          titleSuffix={true}
          description={i18n._(t({ id: 'seo.campiri.description' }))}
          ogDescription={i18n._(t({ id: 'seo.campiri.description' }))}
        />

        <HomePageHeader />
        {/* <VoucherAdvertisementMobile /> */}

        <GoogleReviewsHP />
        {(language === Language.cs || language === Language.pl) && <DynamicPromoBlock />}
        <PageWrapper>
          <HowToStart />
          <Categories />
          <Mission />
          <Favorites featuredCars={featuredCars} />
        </PageWrapper>
      </Layout>
      {country === Country.es && <WhatsappWidget />}
    </>
  );
};

export default Index;

export const getServerSideProps = async ({ res, query }) => {
  res.setHeader('Cache-Control', 'max-age=86400');

  const token = query.cultureToken || 'en-cz';
  axios.defaults.headers['accept-language'] = token;

  let featuredCars = null;

  await getFeaturedCars()
    .then((response) => {
      featuredCars = response.data;
    })
    .catch((e) => {
      console.error(e);
    });

  return {
    props: {
      featuredCars,
    },
  };
};
