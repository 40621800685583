import { FlexCol, FlexColMdMax } from 'ui/primitives/flex';
import tokens from 'ui/design/tokens/tokens';
import { Headline } from 'ui/components/primitives/typography/CustomTypography';
import useFeaturedCars from '~/src/freeway/components/featuredCars/hooks/useFeaturedCars';
import CarItem from '~/src/freeway/components/carItem/CarItemFW';
import { Trans } from '@lingui/react';

export default function RecommendedCars() {
  const { featuredCars } = useFeaturedCars();

  return (
    <FlexCol $gap={tokens.spacing.spacing4Xl}>
      <Headline $size="h3">
        <Trans id="component.reccomendedCars.fwc.title" message="Our recommendations" />
      </Headline>
      <FlexColMdMax $gap={tokens.spacing.spacing4Xl}>
        {featuredCars.slice(0, 3).map((car) => (
          <CarItem openInNewWindow={true} forceVertical={true} key={car.id} carDataProp={car} />
        ))}
      </FlexColMdMax>
    </FlexCol>
  );
}
