import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { connect } from 'react-redux';
import { deviceMaxWidth } from '~/src/styles/breakpoints';

import { HomepageSection, SectionHead, SectionHeaderWrapper, SectionPreHead } from './styles';
import CarItem from '~/src/components/CarItem';
import { ListingSearchQueryResult } from '~/libs/api/models/listing-search-query-result';
import { getFeaturedCars } from '~/src/api/cars';
import { SettingsProps } from '~/src/redux/reducers/settings';

const Favorites = ({
  featuredCars,
  settings,
}: {
  featuredCars: ListingSearchQueryResult[];
  settings: SettingsProps;
}) => {
  const [featuredCarsToShow, setFeaturedCarsToShow] = useState(featuredCars);

  useEffect(() => {
    (async () => {
      getFeaturedCars()
        .then((response) => {
          setFeaturedCarsToShow(response.data);
        })
        .catch((e) => {
          console.error(e);
        });
    })();
  }, [settings.country]);

  return (
    <>
      {featuredCarsToShow && (
        <Wrapper>
          <SectionHeaderWrapper>
            <SectionPreHead>
              <Trans id="mainPage.favorites.sectionPreHead" message="Doporučujeme" />
            </SectionPreHead>
            <SectionHead>
              <Trans id="mainPage.favorites.sectionHead" message="Výběr z oblíbených obytek pro začátek" />
            </SectionHead>
          </SectionHeaderWrapper>

          <FavoriteCars>
            {featuredCarsToShow.map((vehicle, id) => (
              <CarItemWrapper key={vehicle.id}>
                <CarItem dataLayerListName="HP recommended cars" carDataProp={vehicle} />
              </CarItemWrapper>
            ))}
          </FavoriteCars>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled(HomepageSection)``;

export const CarItemWrapper = styled.div`
  max-width: 325px;
  width: 100%;
`;
export const FavoriteCars = styled.div`
  width: 100%;
  display: flex;
  margin: 19px 0 0 0;
  align-items: center;
  > div {
    flex: 1 1 auto;
    margin: 0 16px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
    @media ${deviceMaxWidth.tablet} {
      margin: 0 0 16px;
    }
  }
  @media ${deviceMaxWidth.tablet} {
    flex-direction: column;
  }
`;

const mapStateToProps = (state) => ({
  settings: state.settings,
});

const connector = connect(mapStateToProps, (dispatch) => ({ dispatch }));
export default connector(Favorites);
