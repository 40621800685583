'use client';

import { useDispatch, useSelector } from 'react-redux';
import SearchBar from 'ui/components/searchBar/SearchBar';
import { Box } from 'ui/primitives/box';
import Image from 'next/image';
import { FlexAlignCenter, FlexAlignCenterSpaceBetween, FlexCol } from 'ui/primitives/flex';
import { Headline } from 'ui/components/primitives/typography/CustomTypography';
import { getPopularPlaces, popularSearches } from '../../utils/popularDefinitions';
import tokens from 'ui/design/tokens/tokens';
import { searchInfoSelector } from 'features/redux/selectors/search';
import { makeQueryForSearchFromHP } from 'features/utils/search/utils';
import styled from 'styled-components';
import { Language, routes } from '~/src/utils/routing';
import DynamicSegmentCarousel from '~/src/freeway/components/dynamicSegments/DynamicSegmentCarousel';
import RecommendedCars from '~/src/freeway/pages/homepage/components/RecommendedCars';
import HowToSteps from '~/src/freeway/pages/homepage/components/HowToSteps';
import FeaturesOverview from '~/src/freeway/pages/homepage/components/FeaturesOverview';
import NewsletterSignup from '~/src/freeway/components/NewsletterSignup';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import SocialMedia from '~/src/freeway/pages/homepage/components/SocialMedia';
import { mediaMdMax, mediaXlMin } from 'ui/utils/breakpoints';
import SealOfTrust from './components/SealOfTrust';
import { trackBannerLink } from '~/src/utils/dataLayer';

export default function PageComponent() {
  const dispatch = useDispatch();
  const searchInfo = useSelector(searchInfoSelector);

  const getQueryForSearch = () => makeQueryForSearchFromHP(searchInfo, {}, false);

  return (
    <Box>
      <Box
        $smMin={{
          $height: '568px',
          $mb: '0px',
        }}
        $position="relative"
      >
        <Box
          $position="absolute"
          $overflow="hidden"
          $height="240px"
          $smMin={{
            $height: '100%',
          }}
          $width="100%"
        >
          <StyledImage
            src="/static/images/bg/hero-min2.jpg"
            fill={true}
            priority={true}
            fetchPriority="high"
            alt="Homepage image"
          />
        </Box>
        <FlexCol $margin="auto" $maxWidth="1200px" $width="100%" $zIndex={2} $position="relative">
          <FlexAlignCenterSpaceBetween>
            <StyledHeadline
              $py="40px"
              $pr="16px"
              $pl="16px"
              $height="240px"
              $smMin={{
                $pt: '160px',
                $height: 'auto',
              }}
              $color={tokens.color.backgrounds.surfaceContrast}
              $size="h1"
            >
              <Trans
                id="pages.homepage.heading.option1"
                components={{
                  0: <br />,
                }}
                message="Rent a camper <0/> and feel freedom!"
              />
            </StyledHeadline>
            <SealOfTrust />
          </FlexAlignCenterSpaceBetween>
          <SearchWrapper
            $smMin={{
              $height: '1px',
            }}
          >
            <SearchBar
              onDateChange={(e) => {
                dispatch({
                  type: 'SET_SEARCH_DATES',
                  payload: e.selection,
                });
              }}
              popularSearches={popularSearches}
              popularPlaces={getPopularPlaces()}
              route={routes.search}
              queryForSearch={getQueryForSearch()}
            />
          </SearchWrapper>
        </FlexCol>
      </Box>
      <FeaturesOverview />
      <Box $margin="auto" $maxWidth="1200px">
        <Box
          $px={tokens.spacing.spacingL}
          $py="48px"
          $mdMin={{
            $px: '0px',
            $py: '96px',
          }}
        >
          <DynamicSegmentCarousel
            trackFunction={(e) => trackBannerLink({ link_url: e.currentTarget.href, text: e.currentTarget.innerText })}
            items={[
              {
                title: t({
                  id: 'page.fwc.homepage.deals.1.title',
                  message: 'Last minute deals and special offers',
                }),
                category: t({
                  id: 'page.fwc.homepage.deals.1.subtitle',
                  message: '-20% off',
                }),
                image: '/static/images/homepage/posts/last-minute2.png',
                links: {
                  [Language.en]: {
                    route: routes.blogDetail,
                    linkParams: {
                      slug: 'last-minute-deals-special-offers',
                      id: 539,
                    },
                  },
                  [Language.de]: {
                    route: routes.blogDetail,
                    linkParams: {
                      slug: 'last-minute-deals-angebote',
                      id: 540,
                    },
                  },
                  [Language.it]: {
                    route: routes.blogDetail,
                    linkParams: {
                      slug: 'last-minute-offerte-speciali',
                      id: 541,
                    },
                  },
                },
              },
              {
                title: t({
                  id: 'page.fwc.homepage.deals.2.title',
                  message: 'Travel with dog',
                }),
                category: t({
                  id: 'page.fwc.homepage.deals.2.subtitle',
                  message: 'Pets allowed',
                }),
                image: '/static/images/homepage/posts/do2.png',
                links: {
                  en: {
                    route: routes.dynamicSegmentNew,
                    linkParams: {
                      slug: 'dog-friendly-rv-rental',
                      id: 341,
                    },
                  },
                  de: {
                    route: routes.dynamicSegmentNew,
                    linkParams: {
                      slug: 'wohnmobil-mieten-mit-hund',
                      id: 267,
                    },
                  },
                  it: {
                    route: routes.dynamicSegmentNew,
                    linkParams: {
                      slug: 'noleggio-camper-con-cane',
                      id: 266,
                    },
                  },
                },
              },
              {
                title: t({
                  id: 'page.fwc.homepage.deals.3.title',
                  message: 'Benefits article',
                }),
                category: t({
                  id: 'page.fwc.homepage.deals.3.subtitle',
                  message: 'Benefits',
                }),
                image: '/static/images/homepage/posts/benefits2.png',
                links: {
                  en: {
                    route: routes.blogDetail,
                    linkParams: {
                      slug: 'benefits',
                      id: 554,
                    },
                  },
                  de: {
                    route: routes.blogDetail,
                    linkParams: {
                      slug: 'vorteile',
                      id: 555,
                    },
                  },
                  it: {
                    route: routes.blogDetail,
                    linkParams: {
                      slug: 'vantaggi',
                      id: 556,
                    },
                  },
                },
              },
              {
                title: t({
                  id: 'page.fwc.homepage.deals.4.title',
                  message: 'Enjoy last summer',
                }),
                category: t({
                  id: 'page.fwc.homepage.deals.4.subtitle',
                  message: 'Blog',
                }),
                image: '/static/images/homepage/posts/last-summer.png',
                links: {
                  en: { route: routes.blog },
                  de: { route: routes.blog },
                  it: { route: routes.blog },
                },
              },
            ]}
            showPagination={false}
            title={<Trans id="homepage.fwc.bestDeals.header" message="Choose our best deal!" />}
          />
        </Box>
      </Box>
      <HowToSteps />
      <Box $margin="auto" $maxWidth="1200px">
        <Box
          $px={tokens.spacing.spacingL}
          $py="48px"
          $mdMin={{
            $px: '0px',
            $py: '96px',
          }}
        >
          <RecommendedCars />
        </Box>
      </Box>
      <Box $background={tokens.color.backgrounds.surfaceContrast}>
        <Box $margin="auto" $maxWidth="1200px">
          <Box
            $px={tokens.spacing.spacingL}
            $py="48px"
            $mdMin={{
              $px: '0px',
              $py: '96px',
            }}
          >
            <DynamicSegmentCarousel
              trackFunction={(e) =>
                trackBannerLink({ link_url: e.currentTarget.href, text: e.currentTarget.innerText })
              }
              items={[
                {
                  title: t({
                    id: 'page.fwc.homepage.discover.1.title',
                    message: 'Vans & VW Bulli',
                  }),
                  image: '/static/images/homepage/posts/van.jpg',
                  links: {
                    en: {
                      route: routes.dynamicSegmentNew,
                      linkParams: {
                        slug: 'rent-bulli-van',
                        id: 354,
                      },
                    },
                    de: {
                      route: routes.dynamicSegmentNew,
                      linkParams: {
                        slug: 'bulli-van-mieten',
                        id: 257,
                      },
                    },
                    it: {
                      route: routes.dynamicSegmentNew,
                      linkParams: {
                        slug: 'noleggio-bulli-van',
                        id: 258,
                      },
                    },
                  },
                },
                {
                  title: t({
                    id: 'page.fwc.homepage.discover.2.title',
                    message: 'Campervans',
                  }),
                  image: '/static/images/homepage/posts/campers.jpg',
                  links: {
                    en: {
                      route: routes.dynamicSegmentNew,
                      linkParams: {
                        slug: 'rent-campervan-class-b',
                        id: 351,
                      },
                    },
                    de: {
                      route: routes.dynamicSegmentNew,
                      linkParams: {
                        slug: 'campervan-mieten',
                        id: 260,
                      },
                    },
                    it: {
                      route: routes.dynamicSegmentNew,
                      linkParams: {
                        slug: 'noleggio-camper-clase-b',
                        id: 261,
                      },
                    },
                  },
                },
                {
                  title: t({
                    id: 'page.fwc.homepage.discover.3.title',
                    message: "RV's",
                  }),
                  image: '/static/images/homepage/posts/rv.jpg',
                  links: {
                    en: {
                      route: routes.dynamicSegmentNew,
                      linkParams: {
                        slug: 'rent-rv-class-a-c',
                        id: 347,
                      },
                    },
                    de: {
                      route: routes.dynamicSegmentNew,
                      linkParams: {
                        slug: 'wohnmobil-mieten',
                        id: 263,
                      },
                    },
                    it: {
                      route: routes.dynamicSegmentNew,
                      linkParams: {
                        slug: 'noleggio-camper-classe-a-c',
                        id: 264,
                      },
                    },
                  },
                },
                {
                  title: t({
                    id: 'page.fwc.homepage.discover.4.title',
                    message: 'Extras & Services',
                  }),
                  image: '/static/images/homepage/posts/extras.jpg',
                  links: {
                    en: {
                      route: routes.blogDetail,
                      linkParams: {
                        slug: 'our-additional-services',
                        id: 545,
                      },
                    },
                    de: {
                      route: routes.blogDetail,
                      linkParams: {
                        slug: 'unsere-zusatzservices',
                        id: 546,
                      },
                    },
                    it: {
                      route: routes.blogDetail,
                      linkParams: {
                        slug: 'attrezzatura-e-servizi',
                        id: 547,
                      },
                    },
                  },
                },
              ]}
              subtitle={
                <Trans
                  id="page.homepage.discover.fwc.subtitle"
                  message="Have you been looking for the best camper for you and your loved ones, which is ideally suited to your needs and next camping trip? Then your search is finally over! Here we discuss the following three..."
                />
              }
              showPagination={false}
              title={<Trans id="page.homepage.discover.fwc.title" message="Discover Freeway Camper" />}
            />
          </Box>
        </Box>
      </Box>

      <Box $margin="auto" $maxWidth="1200px">
        <Box
          $px={tokens.spacing.spacingL}
          $py="48px"
          $mdMin={{
            $px: '0px',
            $pt: '96px',
          }}
        >
          <SocialMedia />
        </Box>
      </Box>

      <Box $margin="auto" $maxWidth="1200px">
        <Box
          $px={tokens.spacing.spacingL}
          $py="48px"
          $mdMin={{
            $px: '0px',
            $py: '96px',
          }}
        >
          <NewsletterSignup trackingPage="homepage" />
        </Box>
      </Box>
    </Box>
  );
}

const StyledHeadline = styled(Headline)`
  text-shadow: 0px 4px 4px #00000040;
`;

const SearchWrapper = styled(Box)`
  #autocomplete-list-location ul {
    left: -68px;
    top: 60px;
  }

  #date-dropdown {
    top: 86px;
  }
`;

const StyledImage = styled(Image)`
  object-fit: cover;
  ${mediaMdMax} {
    transform: scale(1.2);
    object-position: 50% 35%;
  }
  ${mediaXlMin} {
    object-position: 50% 35%;
  }
`;
