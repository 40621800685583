import styled from 'styled-components';
import { deviceMaxWidth, deviceMinWidth } from '~/src/styles/breakpoints';
// import { deviceMaxWidth } from '~/src/styles/breakpoints';

const HomepageSection = styled.section`
  width: 100%;
  max-width: 1040px;
  padding: 120px 0 80px 0;
  display: flex;
  flex-direction: column;
  @media ${deviceMaxWidth.tablet} {
    background: none !important;
    padding: 48px 0 40px 0;
  }
`;

const SectionHeaderWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const SectionPreHead = styled.span`
  line-height: 16px;
  font-size: 16px;
  letter-spacing: 1.5px;
  font-family: var(--font-poppins);
  display: inline-block;
  margin-bottom: 28px;
  text-transform: uppercase;
  color: #ff5e55;
  font-weight: 600;

  @media ${deviceMaxWidth.phone} {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 16px;
  }
`;

const SectionHead = styled.h2`
  line-height: 48px;
  font-size: 48px;
  letter-spacing: 1.5px;
  font-family: var(--font-baloo);
  margin-bottom: 20px;
  color: #1f2244;
  font-weight: 600;
  max-width: 720px;
  @media ${deviceMaxWidth.phone} {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 20px;
  }
`;

const SectionDescription = styled.p`
  margin: 0 0 32px 0;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1f2244;
  max-width: 520px;
  @media ${deviceMaxWidth.phone} {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 33px;
  }
`;

const ArrowLink = styled.a`
  /* &:hover,
  &:focus {
    text-decoration: underline;
    .icon-action-forward {
      text-decoration: none;
    }
  } */
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.5px;
  color: var(--color-green);
  text-transform: uppercase;
  vertical-align: middle;
  margin-top: 32px;
  .icon-action-forward {
    font-size: 1rem;
    margin-left: 8px;
  }
`;

const ArrowLinkDesktopVisible = styled.span`
  display: none;
  font-weight: 600;
  @media ${deviceMinWidth.phone} {
    display: inline;
  }
`;

const SearchPlaceholder = styled.div`
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: var(--color-dark-blue);

  @media ${deviceMaxWidth.phone} {
    font-size: 12px;
  }
`;

const SearchInputValue = styled.div`
  margin-top: 8px;
  font-family: var(--font-poppins);
  font-size: 20px;
  line-height: 1;
  color: var(--color-dark-grey);

  @media ${deviceMaxWidth.phone} {
    font-size: 16px;
  }

  &.hasValue {
    color: var(--color-dark-blue);
  }
`;

const ValueCleaner = styled.div`
  position: absolute;
  right: 16px;
  top: 24px;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: var(--color-error-red);
  text-decoration: underline;
`;

export {
  HomepageSection,
  SectionHeaderWrapper,
  SectionPreHead,
  SectionHead,
  SectionDescription,
  ArrowLink,
  ArrowLinkDesktopVisible,
  SearchPlaceholder,
  SearchInputValue,
  ValueCleaner,
};
