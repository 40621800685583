import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { Trans } from '@lingui/react';
import { SettingsProps } from 'features/redux/reducers/settings';
import ImageGoogleLogo from '../../icons/google_logo.svg';
import { mediaSmMax } from '../../utils/breakpoints';
import { resolveInCodebase, resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';

interface ReviewsProps {
  settings: SettingsProps;
  spanishLPVersion?: boolean;
}

function GoogleReviewsHP({ settings, spanishLPVersion = false }: ReviewsProps) {
  const { specialOfferBarVisible } = settings;
  const reviewScore = settings.googleReviews?.stars;
  const reviewsLink =
    'https://www.google.com/maps/place/Campiri/data=!3m1!4b1!4m5!3m4!1s0x470b9525a07bd303:0x40e279432f3b8ab2!8m2!3d49.8037434!4d15.4749126';
  let fullStarsCount = Math.floor(reviewScore);
  let hasHalfStar = false;

  if (reviewScore - Math.floor(reviewScore) >= 0.25 && reviewScore - Math.floor(reviewScore) < 0.75) {
    hasHalfStar = true;
  }
  if (reviewScore - Math.floor(reviewScore) >= 0.75) {
    fullStarsCount++;
  }

  const emptyStarsCount = 5 - Math.floor(fullStarsCount) - (hasHalfStar ? 1 : 0);
  const fullStarsArr = Array(Math.floor(fullStarsCount)).fill('x');
  const emptyStarsArr = Array(Math.floor(emptyStarsCount)).fill('x');

  return (
    <Wrapper specialOfferBarVisible={specialOfferBarVisible} className={spanishLPVersion ? 'noBorder' : ''}>
      <ContentWrapper>
        <a href={reviewsLink} target="_blank" rel="noreferrer">
          <ReviewsHeadingWrapper>
            <Logo>
              <ImageGoogleLogo />
            </Logo>{' '}
            <VL />
            <span>
              <Trans id="footer.section.review" message="Recenze" />
            </span>
          </ReviewsHeadingWrapper>
        </a>
        <a href={reviewsLink} target="_blank" rel="noreferrer">
          <StarsWrapper>
            <StarsNumber>{reviewScore}</StarsNumber>

            {fullStarsArr.map((x, id) => (
              // eslint-disable-next-line react/no-array-index-key
              <StarIconFull key={id} className="icon icon-favorite-active" />
            ))}

            {hasHalfStar && <StarIconHalf className="icon-icon-action-favorite-half-active" />}

            {emptyStarsArr.map((x, id) => (
              // eslint-disable-next-line react/no-array-index-key
              <StarIconEmpty key={id} className="icon icon-favorite" />
            ))}
          </StarsWrapper>
        </a>
        {!spanishLPVersion && (
          <NoticeWrapper
            onClick={() => {
              document.getElementById('autocomplete_input')?.focus();
            }}
          >
            <Trans
              id="header.mainPage.googleReviews.becomeCustomer"
              message="Přidejte se ke stovkám spokojených zákazníků."
            />
          </NoticeWrapper>
        )}
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ specialOfferBarVisible?: boolean }>`
  width: 100%;
  border-bottom: 1px solid var(--color-beige);
  font-family: var(--font-poppins);
  margin-top: ${(props) => (props.specialOfferBarVisible ? '0px' : '40px')};

  .isCAMPIRI & {
    background-color: white;
  }

  ${mediaSmMax} {
    margin-top: ${(props) => (props.specialOfferBarVisible ? '0px' : '20px')};
  }

  ${resolveInCodebase(
    'fwc',
    css`
      margin-top: 0;
    `
  )}

  &.noBorder {
    border-top: none;
    border-bottom: none;
    margin-bottom: 16px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1040px;
  padding: 18px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 auto;

  ${mediaSmMax} {
    justify-content: center;
    padding: 16px;
  }

  ${resolveInCodebase(
    'fwc',
    css`
      max-width: 1200px;
      padding: ${resolveThemeValue(tokens.spacing.spacingL)} 0;
    `
  )}
`;

const Logo = styled.div`
  width: 59px;
  height: 20px;

  ${mediaSmMax} {
    height: 20px;
  }
`;

const VL = styled.div`
  margin: 0 14px;
  height: 20px;
  width: 1px;
  background-color: ${resolveThemeValue(tokens.color.border.borderLight, 'var(--color-dark-grey)')};
`;

const StarsNumber = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #1f2244;
  margin-right: 14px;

  ${mediaSmMax} {
    font-size: 16px;
  }
`;

const StarsWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

const ReviewsHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--color-dark-blue);
  font-size: 16px;

  ${resolveInCodebase(
    'fwc',
    css`
      span {
        font-weight: 600;
      }
    `
  )}

  ${mediaSmMax} {
    font-size: 16px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

const GenericStar = styled.i`
  font-size: 20px;
  margin: 0 2px;
  color: ${resolveThemeValue(tokens.color.buttons.buttonPrimary, 'var(--color-green);')};
  cursor: pointer;

  @media only screen and (max-width: 900px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 340px) {
    font-size: 13px;
  }
`;
const StarIconFull = styled(GenericStar)``;

const StarIconHalf = styled(GenericStar)``;

const StarIconEmpty = styled(GenericStar)``;

const NoticeWrapper = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: ${resolveThemeValue(tokens.color.text.textSecondary, 'var(--color-dark-grey)')};
  cursor: pointer;
  margin-left: auto;

  ${mediaSmMax} {
    display: none;
  }
`;

const mapStateToProps = (state: any) => ({
  settings: state.settings,
});

export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(GoogleReviewsHP);
