import styled from 'styled-components';
import { Box } from './box';
import { resolveThemeValue } from '../design/utils';
import { ColorString } from './mixins';

export default styled(Box).attrs({ as: 'hr', $width: '100%' })<{ $backgroundColor: ColorString }>`
  display: block;
  height: 1px;
  border: 0;
  background: ${({ $backgroundColor }) => resolveThemeValue($backgroundColor)};
`;
