import React from 'react';
import styled from 'styled-components';

import Flickity from 'react-flickity-component';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import Image from 'next/legacy/image';
import { HomepageSection, SectionDescription, SectionHead, SectionHeaderWrapper, SectionPreHead } from './styles';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { Country, Language, routes } from '~/src/utils/routing';
import Image1 from '../../../public/static/compressedImages/homepage/ekologicke-cestovani.png';
import Image2 from '../../../public/static/compressedImages/homepage/family-trip.jpeg';
import Image3 from '../../../public/static/compressedImages/homepage/dobrodruzstvi-pro-dva.png';
import Image4 from '../../../public/static/compressedImages/homepage/poprve-karavanem.png';
import Image5 from '../../../public/static/compressedImages/homepage/vylet-se-psem.png';
import Image6 from '../../../public/static/compressedImages/homepage/luxus-na-kolech.png';
import Image7 from '../../../public/static/compressedImages/homepage/privesy-za-auto.png';
import { useTypedSelector } from '~/src/redux/store';
import { settingsSelector } from '~/src/redux/selectors/settings';
import LocalizedLink from '../LocalizedLink';
import { trackBannerLink, trackHomepageSegmentsClick, tryCallDataLayerEvent } from '~/src/utils/dataLayer';
import { t } from '@lingui/macro';

const flickityOptions = {
  initialIndex: 0,
  contain: true,
  pageDots: false,
  arrowShape: '',
};

const getCategoriesObjectCS = () =>
  [
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.EcologicalTravel' })),
      image: Image1,
      caravansCountString: '433 karavanů',
      slug: 'ekologicke-cestovani-karavanem',
      id: '73',
      key: 'cs1',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.familyExperiences' })),
      image: Image2,
      caravansCountString: '433 karavanů',
      slug: 'obytna-auta-pro-rodinu',
      id: '74',
      key: 'cs2',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.adventureForTwo' })),
      image: Image3,
      caravansCountString: '433 karavanů',
      slug: 'karavany-pro-dva',
      id: '75',
      key: 'cs3',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.firstWithCaravan' })),
      image: Image4,
      caravansCountString: '433 karavanů',
      slug: 'obytna-auta-pro-zacatecniky',
      id: '76',
      key: 'cs4',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.tripWithDog' })),
      image: Image5,
      caravansCountString: '433 karavanů',
      slug: 'karavanem-se-psem',
      id: '78',
      key: 'cs5',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.luxusOnWheels' })),
      image: Image6,
      caravansCountString: '433 karavanů',
      slug: 'luxusni-obytna-auta',
      id: '79',
      key: 'cs6',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.carTrailers' })),
      image: Image7,
      caravansCountString: '433 karavanů',
      slug: 'obytne-privesy',
      id: '80',
      key: 'cs7',
    },
  ] as const;

const getCategoriesObj = () =>
  [
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.EcologicalTravel' })),
      image: Image1,
      caravansCountString: '433 karavanů',
      searchQuery:
        'vehicleType=["NotSet","BuiltIn","SemiIntegrated","Trailer"]&solarPanels=true&yearBuildFrom=2018&yearBuildTo=2021',
      key: 'cat1',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.familyExperiences' })),
      image: Image2,
      caravansCountString: '433 karavanů',
      searchQuery: 'licenseCategory=NotSet&manufacturer&vehicleType=%5B"NotSet"%2C"Alcove"%5D',
      key: 'cat2',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.adventureForTwo' })),
      image: Image3,
      caravansCountString: '433 karavanů',
      searchQuery: 'vehicleType=%5B%22NotSet%22%2C%22BuiltIn%22%2C%22Campervan%22%5D',
      key: 'cat3',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.firstWithCaravan' })),
      image: Image4,
      caravansCountString: '433 karavanů',
      searchQuery: 'vehicleType=%5B"NotSet"%2C"BuiltIn"%2C"SemiIntegrated"%2C"Alcove"%5D',
      key: 'cat4',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.tripWithDog' })),
      image: Image5,
      caravansCountString: '433 karavanů',
      searchQuery: 'pets=%5B"Allowed"%5D',
      key: 'cat5',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.luxusOnWheels' })),
      image: Image6,
      caravansCountString: '433 karavanů',
      searchQuery: 'vehicleType=%5B"NotSet"%2C"Integrated"%5D',
      key: 'cat6',
    },
    {
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.carTrailers' })),
      image: Image7,
      caravansCountString: '433 karavanů',
      searchQuery: 'vehicleType=%5B"NotSet"%2C"Trailer"%5D',
      key: 'cat7',
    },
  ] as const;

const Categories = () => {
  const settings = useTypedSelector(settingsSelector);
  const isCS = settings.country === Country.cz && settings.language === Language.cs;
  return (
    <Wrapper>
      <SectionHeaderWrapper>
        <SectionPreHead>
          <Trans id="mainPage.campiriLifestyle.sectionPreHead" message="Campiri životní styl" />
        </SectionPreHead>
        <SectionHead>
          <Trans id="mainPage.campiriLifestyle.sectionHead" message="Svobodné cestování s důrazem na udržitelnost" />
        </SectionHead>
        <SectionDescription>
          <Trans
            id="mainPage.campiriLifestyle.sectionDescription"
            message="Hledáte luxusní karavan pro celou rodinu, levnou obytku na road trip nebo ekologickou vestavbu pro udržitelné cestování? Vyberte si ze stovek karavanů podle svých preferencí a zažijte spoustu nezapomenutelných zážitků."
          />
        </SectionDescription>
      </SectionHeaderWrapper>
      <CategoriesCarouselWrapper>
        {isCS && (
          <Flickity
            className="carousel"
            elementType="div"
            options={flickityOptions}
            disableImagesLoaded={true}
            static={true} // default false
          >
            {getCategoriesObjectCS().map((category, id) => (
              <LocalizedLink
                key={category.key}
                passHref={true}
                linkParams={{
                  slug: category.slug,
                  id: category.id,
                }}
                href={routes.dynamicSegmentNew}
                legacyBehavior={false}
                forceLegacyFlag={true}
                onClick={(e) => trackBannerLink({ link_url: e.currentTarget.href, text: e.currentTarget.innerText })}
              >
                <CategoriesCarouselItem>
                  <Image alt="Adventure Image" src={category.image} layout="fill" objectFit="cover" quality={50} />
                  <CategoriesCarouselItemTitle>
                    <CategoriesCarouselItemTitleHead>
                      <Trans id={category.name} />{' '}
                    </CategoriesCarouselItemTitleHead>
                    {/* <CategoriesCarouselItemTitlePostHead>
                      {category.caravansCountString}
                    </CategoriesCarouselItemTitlePostHead> */}
                  </CategoriesCarouselItemTitle>
                </CategoriesCarouselItem>
              </LocalizedLink>
            ))}
          </Flickity>
        )}
        {!isCS && (
          <Flickity
            className="carousel"
            elementType="div"
            options={flickityOptions}
            disableImagesLoaded={true}
            static={true} // default false
          >
            {getCategoriesObj().map((category, id) => (
              <LocalizedLink
                key={category.key}
                passHref={true}
                query={category?.searchQuery}
                linkParams={{}}
                href={routes.search}
                legacyBehavior={false}
                forceLegacyFlag={true}
                onClick={(e) => trackBannerLink({ link_url: e.currentTarget.href, text: e.currentTarget.innerText })}
              >
                <CategoriesCarouselItem>
                  <Image alt="Adventure Image" src={category.image} layout="fill" objectFit="cover" quality={50} />
                  <CategoriesCarouselItemTitle>
                    <CategoriesCarouselItemTitleHead>
                      <Trans id={category.name} />{' '}
                    </CategoriesCarouselItemTitleHead>
                    {/* <CategoriesCarouselItemTitlePostHead>
                      {category.caravansCountString}
                    </CategoriesCarouselItemTitlePostHead> */}
                  </CategoriesCarouselItemTitle>
                </CategoriesCarouselItem>
              </LocalizedLink>
            ))}
          </Flickity>
        )}
      </CategoriesCarouselWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(HomepageSection)`
  background-image: url('/static/images/vectors/water.svg');
  background-repeat: no-repeat;
  background-size: 50% 50%;
  background-position: 100% 0px;
  border-bottom: 1px solid var(--color-beige);
  position: relative;
`;

const CategoriesCarouselWrapper = styled.div`
  width: 100%;
  /* height: 280px; */

  .flickity-viewport {
    /* height: 280px; */
  }

  .flickity-prev-next-button {
    &:before {
      content: '';
      background-image: url('/static/images/vectors/flickity_arrow.svg');
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      position: absolute;
    }

    /* position: relative; */
    @media only screen and (max-width: 1150px) {
      display: none;
    }
  }

  .flickity-prev-next-button.previous {
    left: -60px;
  }

  .flickity-prev-next-button.next {
    right: -45px;

    &:before {
      transform: rotate(180deg);
    }
  }
`;

const CategoriesCarouselItem = styled.div`
  width: 245px;
  border-radius: 8px;
  height: 280px;
  overflow: hidden;
  position: relative;
  margin-right: 20px;
  @media ${deviceMaxWidth.tablet} {
    width: 200px;
    height: 231px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  }
`;

const CategoriesCarouselItemImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

const CategoriesCarouselItemTitle = styled.div`
  width: 170px;
  height: auto;
  position: absolute;
  bottom: 17px;
  left: 12px;
  z-index: 1;
`;

const CategoriesCarouselItemTitlePostHead = styled.span`
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--color-grey);
`;

const CategoriesCarouselItemTitleHead = styled.h4`
  margin: 0 0 4px;
  font-family: var(--font-baloo);
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--color-beige);
`;

export default Categories;
