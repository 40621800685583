import { AxiosRequestConfig } from 'axios';
import getConfig from 'next/config';
import { ArticlePlacementType } from '~/libs/api/models/article-placement-type';
import { Configuration } from '~/libs/api/configuration';
import { NewsLetterMarketplaceApi } from '~/libs/api/apis/news-letter-marketplace-api';

const getAccessToken = () => {
  if (typeof window !== 'undefined') {
    const accessToken = window?.localStorage?.getItem('accessToken');
    return accessToken;
  }
  return null;
};

const { publicRuntimeConfig } = getConfig();

const getApiConfig = () => new Configuration({
  basePath: publicRuntimeConfig.API_HOST.replace(/\/+$/, ''),
  accessToken: getAccessToken,
});

const apiConfiguration = getApiConfig();

const NEWSLETTER_API = new NewsLetterMarketplaceApi(apiConfiguration, apiConfiguration.basePath);

export const postNewsletterSubscribe = async (email: string | null, options?: AxiosRequestConfig) => NEWSLETTER_API.apiV1MarketplaceNewsletterSubscribePost({ email }, options);
export const postNewsletterUnSubscribe = async (email: string | null, options?: AxiosRequestConfig) => NEWSLETTER_API.apiV1MarketplaceNewsletterUnsubscribePost({ email }, options);
