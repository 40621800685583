import { useTypedSelector } from '~/src/redux/store';
import { languageSelector } from '~/src/redux/selectors/settings';
import { Language } from 'features/utils/routing';
import { mediaSmMax } from 'ui/utils/breakpoints';
import styled from 'styled-components';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';

function getSealImageSource(language: Language) {
  if (language === Language.de) return '/fwc-static/seal/seal-2024-DE.svg';
  if (language === Language.it) return '/fwc-static/seal/seal-2024-IT.svg';
  return '/fwc-static/seal/seal-2024-EN.svg';
}

interface SealOfTrustProps {
  isMobile?: boolean;
}

export default function SealOfTrust({ isMobile = false }: SealOfTrustProps) {
  const language = useTypedSelector(languageSelector);

  return (
    <SealWrapper isMobile={isMobile}>
      <img
        alt="Seal of trust"
        height={isMobile ? 143 : 180}
        width={isMobile ? 143 : 180}
        src={getSealImageSource(language)}
      />
    </SealWrapper>
  );
}

const SealWrapper = styled.div<{ isMobile: boolean }>`
  display: ${(props) => (props.isMobile ? 'none' : 'block')};
  padding-bottom: ${({ isMobile }) => (isMobile ? resolveThemeValue(tokens.spacing.spacingL) : 0)};

  ${mediaSmMax} {
    display: ${(props) => (props.isMobile ? 'flex' : 'none')};
    justify-content: center;
  }
`;
