import tokens from 'ui/design/tokens/tokens';
import { FlexCol } from 'ui/primitives/flex';
import { Box } from 'ui/primitives/box';
import { Body, Headline } from 'ui/components/primitives/typography/CustomTypography';
import PrimaryButton from 'ui/components/buttons/PrimaryButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import useBreakpoints, { SMALL_SCREEN_QUERY } from 'features/hooks/useBreakpoints';
import { Typography } from 'ui/primitives/typography';
import useScrollToTop from '~/src/freeway/hooks/useScrollToTop';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import styled from 'styled-components';

const getSteps = () => [
  {
    title: i18n._(
      t({
        id: 'howToStep.fwc.selectStation.heading',
        message: 'Select pickup station and date',
      })
    ),
    text: t({
      id: 'howToStep.fwc.selectStation.subheading',
      message: 'Write where you want to go and when. Specify the number of people taking part in the trip.',
    }),
  },
  {
    title: t({
      id: 'howToStep.fwc.chooseCamper.heading',
      message: 'Choose a camper',
    }),
    text: t({
      id: 'howToStep.fwc.chooseCamper.subheading',
      message: 'Choose the camper that suits you best.',
    }),
  },
  {
    title: t({
      id: 'howToStep.fwc.pickup.heading',
      message: 'Pickup your camper',
    }),
    text: t({
      id: 'howToStep.fwc.pickup.subheading',
      message: 'Pickup an camper from the station of your choice',
    }),
  },
  {
    title: t({
      id: 'howToStep.fwc.hitRoad.heading',
      message: 'Hit the road!',
    }),
    text: t({
      id: 'howToStep.fwc.hitRoad.subheading',
      message:
        'The Freeway Camper team will train you on how to use the camper and you can start your camper adventure!',
    }),
  },
  {
    title: t({
      id: 'howToStep.fwc.return.heading',
      message: 'Return a camper',
    }),
    text: t({
      id: 'howToStep.fwc.return.subheading',
      message: 'Return the camper to the station from which you picked up the camper',
    }),
  },
];

export default function HowToSteps() {
  const [smallScreen] = useBreakpoints(SMALL_SCREEN_QUERY);

  const scrollToTop = useScrollToTop();

  return (
    <Box $background={tokens.color.backgrounds.surfaceContrast}>
      <FlexCol
        $px={tokens.spacing.spacingL}
        $py="48px"
        $gap={tokens.spacing.spacing4Xl}
        $mdMin={{
          $px: '0px',
          $py: '96px',
        }}
        $maxWidth="1200px"
        $margin="auto"
      >
        <Headline $size="h3">
          <Trans
            id="pages.homepage.howTo.heading"
            components={{
              0: <br />,
            }}
            message="Your camper adventure <0/> begins in a 5 steps"
          />
        </Headline>
        <Wrapper>
          <Swiper
            pagination={{
              type: 'bullets',
              el: '#pagination',
              clickable: true,
            }}
            breakpoints={{
              800: {
                slidesPerView: 3.5,
                spaceBetween: 16,
              },
              1000: {
                slidesPerView: 5,
                spaceBetween: 32,
              },
            }}
            autoHeight={false}
            slidesPerView={1.4}
            spaceBetween={16}
          >
            {getSteps().map((step, index) => (
              <SwiperSlide key={step.title}>
                <FlexCol
                  // $minHeight="100%"
                  $borderRadius={tokens.radius.radiusL}
                  $border="1px solid"
                  // $mdMin={{
                  //   $minHeight: '100%',
                  // }}
                  $borderColor={tokens.color.border.border}
                  $padding={tokens.spacing.spacing2Xl}
                  $background={tokens.color.backgrounds.surfaceBg}
                  $gap={tokens.spacing.spacingM}
                >
                  <div>
                    <Typography
                      $height="28px"
                      $width="28px"
                      $tAlign="center"
                      $pt="4px"
                      $borderRadius="50%"
                      $border="1px solid"
                      $borderColor={tokens.color.border.border}
                    >
                      {index + 1}
                    </Typography>
                  </div>
                  <FlexCol $gap={tokens.spacing.spacingS}>
                    <Body $size="lBold">{step.title}</Body>
                    <Body $color={tokens.color.text.textSecondary} $size="m">
                      {step.text}
                    </Body>
                  </FlexCol>
                </FlexCol>
              </SwiperSlide>
            ))}
          </Swiper>
        </Wrapper>
        <Box $width="fit-content">
          <PrimaryButton onClick={scrollToTop}>
            <Trans id="howToStep.fwc.letGo" message={"Let's go"} />
          </PrimaryButton>
        </Box>
      </FlexCol>
    </Box>
  );
}

const Wrapper = styled.div`
  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    display: flex;
    height: auto;
  }
`;
