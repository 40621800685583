import { useQuery } from '@tanstack/react-query';
import { getFeaturedCars } from '~/src/api/cars';

export default function useFeaturedCars() {
  const { data: { data: featuredCars = [] } = {}, ...rest } = useQuery({
    queryKey: ['useFeaturedCars'],
    queryFn: () => getFeaturedCars(),
  });

  return {
    featuredCars,
    ...rest,
  };
}
