import React, { FC } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { i18n } from '@lingui/core';
import { cultureTokenMap, Language } from '~/src/utils/routing';
import { deviceMaxWidth, deviceMinWidth } from '~/src/styles/breakpoints';
import { useLocalizedRouter } from '~/src/components/LocalizedLink';
import { setCountry, setLanguage } from '~/src/redux/actions/settings';
import PlFlagImage from './flags/PlFlagImage';

interface ChangeLanguageBarProps {
  newLanguage: Language;
  closeLanguageBar: () => void;
  visible: boolean;
}

export const ChangeLanguageBar: FC<ChangeLanguageBarProps> = ({ closeLanguageBar, newLanguage, visible }) => {
  const router = useLocalizedRouter();
  const dispatch = useDispatch();

  const isSearchPage = router.pathname === '/search';
  const isBookingPage = router.pathname === '/booking/[bookingId]';

  const changeLanguage = () => {
    if (newLanguage) {
      router.changeLanguage(newLanguage);
      dispatch(setLanguage(newLanguage));
      dispatch(setCountry(cultureTokenMap[newLanguage].split('-')[1]));
      i18n.activate(newLanguage);
    }
  };

  if (!visible) return null;
  return (
    <Wrapper className={isSearchPage ? 'isSearchPage' : ''}>
      <ContentWrapper isWider={isSearchPage || isBookingPage}>
        <MobileVersion>
          {newLanguage === Language.pl && (
            <>
              <FlagImg>
                <PlFlagImage />
              </FlagImg>

              <InfoText>Campiri dostępne jest również w języku polskim.</InfoText>

              <ChangeLanguageLink onClick={changeLanguage}> Przełącz na język polski</ChangeLanguageLink>
            </>
          )}

          {newLanguage === Language.cs && (
            <>
              <FlagImg src="/static/images/flags/cz.svg" />

              <InfoText>Campiri je k dispozici také v češtine.</InfoText>

              <ChangeLanguageLink onClick={changeLanguage}> Přepnout do češtiny</ChangeLanguageLink>
            </>
          )}
          <CloseIcon onClick={() => closeLanguageBar()} className="icon icon-close" />
        </MobileVersion>
        <DesktopVersion>
          <>
            {newLanguage === Language.pl && (
              <>
                <FlagImg>
                  <PlFlagImage />
                </FlagImg>

                <InfoText>Campiri dostępne jest również w języku polskim.</InfoText>

                <ChangeLanguageLink onClick={changeLanguage}> Przełącz na język polski</ChangeLanguageLink>
              </>
            )}

            {newLanguage === Language.cs && (
              <>
                <FlagImg src="/static/images/flags/cz.svg" />

                <InfoText>Campiri je k dispozici také v češtine.</InfoText>

                <ChangeLanguageLink onClick={changeLanguage}> Přepnout do češtiny</ChangeLanguageLink>
              </>
            )}
            <CloseIcon onClick={() => closeLanguageBar()} className="icon icon-close" />
          </>
        </DesktopVersion>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.a`
  position: relative;
  width: 100%;
  min-height: 60px;
  height: 100%;
  padding: 12px;
  background-color: var(--color-beige);
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color-white);
  display: flex;
  top: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;

  &.isSearchPage {
    @media ${deviceMaxWidth.phone} {
      position: inherit;
    }
  }
`;

const FlagImg = styled.img`
  margin-right: 8px;
`;

const InfoText = styled.span`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #1f2244;
  padding-right: 12px;
  border-right: 1px solid var(--color-grey);
`;

const ChangeLanguageLink = styled.span`
  padding-left: 12px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color-orange);
  cursor: pointer;
`;

const ContentWrapper = styled.div<{ isWider?: boolean }>`
  max-width: ${(props) => (props.isWider ? '1240px' : '1040px')};
  width: 100%;
  margin: 0 auto;
  display: flex;
`;

const MobileVersion = styled.div`
  /* font-weight: 600; */
  width: 100%;
  align-items: center;
  display: none;

  @media ${deviceMaxWidth.phone} {
    display: flex;
  }
`;

const CloseIcon = styled.i`
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  margin-left: auto;
  color: black;
`;

const DesktopVersion = styled.div`
  /* font-weight: 600; */
  width: 100%;
  align-items: center;

  display: none;

  @media ${deviceMinWidth.phone} {
    display: flex;
  }
`;

export default ChangeLanguageBar;
