import { FlexAlignCenter, FlexCol, FlexColMdMax } from 'ui/primitives/flex';
import Image from 'next/image';
import { Box } from 'ui/primitives/box';
import styled from 'styled-components';
import tokens from 'ui/design/tokens/tokens';
import { Body, Headline } from 'ui/components/primitives/typography/CustomTypography';
import PrimaryButton from 'ui/components/buttons/PrimaryButton';
import ArrowRight from 'ui/icons/ic_arrow_right.svg';
import { mediaMdMin } from 'ui/utils/breakpoints';

import { useForm, useWatch } from 'react-hook-form';
import Checkbox from 'ui/components/inputs/Checkbox';
import { resolveThemeValue } from 'ui/design/utils';
import { Trans } from '@lingui/react';
import { postNewsletterSubscribe } from '~/src/api/newsletter';
import { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { settingsSelector } from 'features/redux/selectors/settings';
import { Language } from 'features/utils/routing';
import { i18n } from '@lingui/core';

interface Props {
  trackingPage?: string;
}

export default function NewsletterSignup({ trackingPage = 'unknown' }: Props) {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const settings = useSelector(settingsSelector);
  const { register, control, setValue, handleSubmit, reset, errors, setError } = useForm({
    reValidateMode: 'onChange',
    mode: 'onTouched',
    defaultValues: {
      email: '',
      acceptTerms: false,
    },
  });

  const acceptedTerms = useWatch<boolean>({
    name: 'acceptTerms',
    control,
  });

  const emailLink = useMemo(() => {
    switch (settings.language) {
      case Language.en:
        return 'http://eepurl.com/iaPMfr';
      case Language.it:
        return 'http://eepurl.com/iaPKgj';
      case Language.de:
        return 'http://eepurl.com/gPXkU5';
      default:
        return 'http://eepurl.com/iaPMfr';
    }
  }, []);

  const submit = async (data) => {
    if (!data.acceptTerms) {
      setError('acceptTerms', {
        type: 'required',
        message: 'missing consent',
      });
      return;
    }

    try {
      await postNewsletterSubscribe(data.email);
      setShowSuccessMessage(true);
      reset();
    } catch (e) {
      console.error("couldn't send an email");
    }
  };

  const track = () => {
    window.dataLayer.push({
      event: 'newsletter_subscribe',
      newsletter_subscribe: {
        name: 'newsletter subscribe',
        type: 'widget',
        area: trackingPage, // blog, segments etc.
        url: 'URL',
        text: t({ id: 'component.fwc.newsletter.subscribe' }), // button text
      },
      _clear: true,
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Wrapper
        $border="1px solid"
        $borderColor={tokens.color.backgrounds.disabled}
        $borderRadius={tokens.radius.radiusL}
        $overflow="hidden"
      >
        <div>
          <Box
            $position="relative"
            $height="270px"
            $mdMin={{
              $width: '564px',
              $height: '100%',
            }}
          >
            <StyledImage src="/static/images/bg/email-sub2.png" fill={true} alt="email signup alt" />
          </Box>
        </div>
        <FlexCol
          $background={tokens.color.backgrounds.surfaceContrast}
          $pt={tokens.spacing.spacing2Xl}
          $pl={tokens.spacing.spacingL}
          $pr={tokens.spacing.spacingL}
          $pb={tokens.spacing.spacing4Xl}
          $gap={tokens.spacing.spacing2Xl}
          $mdMin={{
            $padding: tokens.spacing.spacing5Xl,
          }}
        >
          <Headline $size="h3">
            <Trans id="component.fwc.newsletter.title" message="Subscribe to our newsletter and get 50€ off" />
          </Headline>
          <Body $color={tokens.color.text.textSecondary} $size="l">
            <Trans
              id="component.fwc.newsletter.subtitle"
              message="If you would like to receive information about our news, tips and tricks about camping, special deals and
          events sign up for our newsletter."
            />
          </Body>
          {/* <FlexCol> */}
          {/*  <InputText */}
          {/*    id="email" */}
          {/*    errorMessage={i18n._(t({ id: 'inputText.labels.email.required' }))} */}
          {/*    hasError={!!errors.email} */}
          {/*    showError={!!errors.email} */}
          {/*    correctMessage={ */}
          {/*      showSuccessMessage */}
          {/*        ? i18n._(t({ id: 'inputText.newsletter.success', message: 'Thank you for subscribing' })) */}
          {/*        : null */}
          {/*    } */}
          {/*    refs={register({ */}
          {/*      required: true, */}
          {/*      validate: { */}
          {/*        hasAt: (value) => value.includes('@') || i18n._(t({ id: 'inputText.labels.email.error.missingAt' })), */}
          {/*        isValidFormat: (value) => */}
          {/*          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) || i18n._(t({ id: 'inputText.labels.email.error' })), */}
          {/*      }, */}
          {/*    })} */}
          {/*    type="email" */}
          {/*    label={t({ id: 'component.fwc.newsletter.inputLabel', message: 'Enter your e-mail' })} */}
          {/*    name="email" */}
          {/*  /> */}
          {/* </FlexCol> */}
          {/* <StyledCheckbox */}
          {/*  labelTranslComp={ */}
          {/*    <Trans */}
          {/*      id="component.fwc.newsletter.checkbox" */}
          {/*      message="By registering and providing your information, you agree to receive emails for marketing purposes from FreewayCamper. Additional information can be found in our privacy policy." */}
          {/*    /> */}
          {/*  } */}
          {/*  name="checkbox" */}
          {/*  errorMessage={errors.acceptTerms?.message} */}
          {/*  isSelected={acceptedTerms as boolean} */}
          {/*  boxOnLeft={true} */}
          {/*  onCheckboxChange={(e) => { */}
          {/*    setValue('acceptTerms', e.target.checked); */}
          {/*  }} */}
          {/* /> */}
          {/* <Box $display="none" as="input" type="checkbox" name="acceptTerms" ref={register()} /> */}
          <a href={emailLink} onClick={() => track()} target="_blank" rel="noopener noreferrer">
            <PrimaryButton type="button">
              <FlexAlignCenter $gap={tokens.spacing.spacingM}>
                <div>
                  <ArrowRight height={16} />
                </div>
                <span>
                  <Trans id="component.fwc.newsletter.subscribe" message="Subscribe" />
                </span>
              </FlexAlignCenter>
            </PrimaryButton>
          </a>
        </FlexCol>
      </Wrapper>
    </form>
  );
}

const StyledImage = styled(Image)`
  object-fit: cover;
`;

const StyledCheckbox = styled(Checkbox)`
  .checkBoxLabel span {
    color: ${resolveThemeValue(tokens.color.text.textSecondary)};
  }
`;

const Wrapper = styled(FlexColMdMax)`
  ${mediaMdMin} {
    flex-direction: row-reverse;
  }
`;
