import { useEffect, useState } from 'react';
import { Country, Language } from '~/src/utils/routing';
import { useTypedSelector } from '~/src/redux/store';
import { countrySelector, languageSelector } from '~/src/redux/selectors/settings';
import { getLangFromBrowser } from '~/src/components/layout/getLangFromBrowser';

export const useDisplayChangeLanguageBar = () => {
  const country = useTypedSelector(countrySelector);
  const language = useTypedSelector(languageSelector);

  const [visible, setIsVisible] = useState(false);
  const [newLanguage, setNewLanguage] = useState(null);

  useEffect(() => {
    const hideLanguageBar = window?.localStorage?.getItem('languageBarVisible') === 'false';
    const langFromBrowser = getLangFromBrowser();

    if (
      (langFromBrowser === 'pl' || langFromBrowser === 'pl-PL') &&
      country === Country.cz &&
      language === Language.cs
    ) {
      setIsVisible(true);

      setNewLanguage(Language.pl);
    }

    if (
      (langFromBrowser === 'cs' || langFromBrowser === 'cs-CZ') &&
      country === Country.pl &&
      language === Language.pl
    ) {
      setIsVisible(true);

      setNewLanguage(Language.cs);
    }

    if (hideLanguageBar) {
      setIsVisible(false);
    }
  }, [country, language]);

  const closeLanguageBar = () => {
    setIsVisible(false);
    window?.localStorage?.setItem('languageBarVisible', 'false');
  };

  return { closeLanguageBar, visible, newLanguage };
};
