import { Box } from 'ui/primitives/box';
import { Flex, FlexAlignCenter, FlexColMdMax } from 'ui/primitives/flex';
import tokens from 'ui/design/tokens/tokens';
import AdventureIcon from 'ui/icons/ic_adventure.svg';
import PhoneSupportIcon from 'ui/icons/ic_phone_support.svg';
import CalendarIcon from 'ui/icons/ic_calendar.svg';
import FavoriteOutlineIcon from 'ui/icons/ic_favorite_outline.svg';
import { Typography } from 'ui/primitives/typography';
import styled from 'styled-components';
import { mediaMdMin } from 'ui/utils/breakpoints';
import { Trans } from '@lingui/react';
import SealOfTrust from './SealOfTrust';

export default function FeaturesOverview() {
  return (
    <Box $background={tokens.color.backgrounds.surfaceContrast}>
      <Flex
        $padding={tokens.spacing.spacingL}
        $mdMin={{
          $py: tokens.spacing.spacingL,
          $px: '0px',
        }}
        $maxWidth="1200px"
        $margin="auto"
      >
        <ItemWrapper $width="100%" $gap={tokens.spacing.spacingM}>
          <SealOfTrust isMobile={true} />
          <FlexAlignCenter $gap={tokens.spacing.spacingS}>
            <AdventureIcon height={24} />
            <Typography $fWeight={600} $fSize="12px">
              <Trans id="pages.homepage.features.fairPrice" message="Fair price guarantee" />
            </Typography>
          </FlexAlignCenter>
          <FlexAlignCenter $gap={tokens.spacing.spacingS}>
            <CalendarIcon fill="#C8802E" height={24} />
            <Typography $fWeight={600} $fSize="12px">
              <Trans id="pages.homepage.features.support" message="Real people here to help 24/7" />
            </Typography>
          </FlexAlignCenter>
          <FlexAlignCenter $gap={tokens.spacing.spacingS}>
            <PhoneSupportIcon fill="#C8802E" height={24} />
            <Typography $fWeight={600} $fSize="12px">
              <Trans id="pages.homepage.features.assitance" message="24/7 roadside assistance & insurance" />
            </Typography>
          </FlexAlignCenter>
          <FlexAlignCenter $gap={tokens.spacing.spacingS}>
            <FavoriteOutlineIcon fill="#C8802E" height={24} />
            <Typography $fWeight={600} $fSize="12px">
              <Trans id="pages.homepage.features.rating" message="4.9 Rating in Google Reviews" />
            </Typography>
          </FlexAlignCenter>
        </ItemWrapper>
      </Flex>
    </Box>
  );
}

const ItemWrapper = styled(FlexColMdMax)`
  ${mediaMdMin} {
    justify-content: space-between;
  }
`;
