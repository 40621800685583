import React, { Dispatch, useState } from 'react';
import styled, { css } from 'styled-components';
import { Trans } from '@lingui/react';
import LocalizedLink from 'features/routing/LocalizedLink';
import { RouteType } from 'features/routing/types';
import { useCodebase } from 'features/hooks/useCodebase';
import { mediaMdMin, mediaSmMax } from '../../utils/breakpoints';
import { resolveInCodebase, resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';
import PlaceAutocomplete from './PlaceAutocomplete';
import PrimaryButton from '../buttons/PrimaryButton';
import { PlaceType } from './utils/utils';
import DateRangeCalendar from './DateRangeCalendar';

interface Props {
  queryForSearch: any;
  route: RouteType;
  customClearSearchSideEffect?: () => void;
  popularPlaces: PlaceType;
  popularSearches: PlaceType;
  dynamicSegmentRoute?: RouteType;
  onDateChange: (date: { selection: any }, dispatch: Dispatch<any>) => void;
}

export default function SearchBar({
  queryForSearch,
  route,
  customClearSearchSideEffect,
  popularSearches,
  popularPlaces,
  onDateChange,
  dynamicSegmentRoute,
}: Props) {
  const [placeError, setPlaceError] = useState(false);
  const isFWC = useCodebase('fwc');

  return (
    <Wrapper>
      <PlaceAutocomplete
        popularSearches={popularSearches}
        popularPlaces={popularPlaces}
        customClearSearchSideEffect={customClearSearchSideEffect}
        placeError={placeError}
        setPlaceError={setPlaceError}
      />
      <VercticalLine />

      <DateRangeCalendar
        popularSearches={popularSearches}
        popularPlaces={popularPlaces}
        dynamicSegmentRoute={dynamicSegmentRoute}
        onDateChange={onDateChange}
        monthsBreakPoint={1400}
      />
      {!isFWC && <VercticalLine />}

      <SearchCTAWrapper
        onClick={() => {
          // tryCallDataLayerEvent(() => { trackSearchDataLayer(searchInfo.selectedDays, searchInfo.place); });
        }}
      >
        <LocalizedLink href={route} query={queryForSearch} passHref={false}>
          <PrimaryButton dataTestId="search-campervan-cta">
            <Trans id="button.searchCaravan" message="Vyhledat karavan" />
          </PrimaryButton>
        </LocalizedLink>
      </SearchCTAWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1060px;
  width: 100%;
  height: 100%;
  min-height: 96px;
  max-height: 100px;
  margin: 80px 0 10px;
  border-radius: 16px;
  box-shadow: 0 8px 36px rgba(0, 0, 0, 0.1);
  background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceBg, 'var(--color-white)')};
  ${resolveInCodebase(
    'fwc',
    css`
      max-width: 1200px;
      padding: ${resolveThemeValue(tokens.spacing.spacingL)};
      border-radius: ${resolveThemeValue(tokens.radius.radiusL)};
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
      margin: 0;
    `
  )}

  .isCAMPIRI & {
    align-items: stretch;
  }

  ${mediaSmMax} {
    max-height: unset;
    flex-direction: column;
    max-width: unset;
    width: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
      'place place'
      'date passengers'
      'cta cta';
    border-radius: 16px;
    margin: 40px 0 10px;
    width: 100vw;
    margin-left: -16px;
    ${resolveInCodebase(
      'fwc',
      css`
        margin: 0;
        border-radius: 0;
        padding: 0;
      `
    )}
  }
`;

const SearchCTAWrapper = styled.div`
  margin-right: 16px;
  margin-left: 16px;

  .isFWC & {
    ${mediaMdMin} {
      min-width: 270px;
    }
  }

  .isCAMPIRI & {
    display: flex;
    align-items: center;
  }

  ${mediaSmMax} {
    grid-area: cta;
    margin-right: 0px;
    margin-left: 0px;

    padding: 16px;
    background: white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    box-shadow: 0px 9px 12px 0 rgb(31 34 68 / 20%);
    ${resolveInCodebase(
      'fwc',
      css`
        box-shadow: none;
      `
    )}
  }

  button {
    white-space: nowrap;
  }
`;

const VercticalLine = styled.div`
  .isFWC & {
    height: 100%;
  }

  width: 1px;
  background-color: ${resolveThemeValue(tokens.color.border.borderLight, 'var(--color-beige)')};
`;
