import { Flex, FlexAlignCenter, FlexCenterBoth, FlexCol, FlexColAlignCenter, FlexWrap } from 'ui/primitives/flex';
import IG from 'ui/icons/ic_instagram.svg';
import FB from 'ui/icons/ic_facebook.svg';
import YT from 'ui/icons/ic_youtube.svg';
import styled from 'styled-components';
import { Body } from 'ui/components/primitives/typography/CustomTypography';
import tokens from 'ui/design/tokens/tokens';
import Divider from 'ui/primitives/divider';
import Image from 'next/image';
import { Box } from 'ui/primitives/box';
import { resolveThemeValue } from 'ui/design/utils';
import { mediaMdMin } from 'ui/utils/breakpoints';
import { Trans } from '@lingui/react';

export default function SocialMedia() {
  return (
    <Wrapper>
      <FlexWrap
        $width="100%"
        $mdMin={{
          $width: '50%',
          $gap: tokens.spacing.spacing4Xl,
        }}
        $gap={tokens.spacing.spacingL}
      >
        <Box
          $position="relative"
          $width="47.3%"
          $height="143px"
          $mdMin={{
            $width: '45%',
            $height: '240px',
          }}
        >
          <StyledImage sizes="300px" alt="image" src="/static/images/homepage/fwc-posts/ig1new.png" fill={true} />
        </Box>
        <Box
          $position="relative"
          $width="47.3%"
          $height="143px"
          $mdMin={{
            $height: '240px',
            $width: '45%',
          }}
        >
          <StyledImage sizes="300px" alt="image" src="/static/images/homepage/fwc-posts/ig2new.png" fill={true} />
        </Box>
        <Box
          $position="relative"
          $width="47.3%"
          $height="143px"
          $mdMin={{
            $height: '240px',
            $width: '45%',
          }}
        >
          <StyledImage sizes="300px" alt="image" src="/static/images/homepage/fwc-posts/ig3.png" fill={true} />
        </Box>
        <Box
          $position="relative"
          $width="47.3%"
          $height="143px"
          $mdMin={{
            $height: '240px',
            $width: '45%',
          }}
        >
          <StyledImage sizes="300px" alt="image" src="/static/images/homepage/fwc-posts/ig4.png" fill={true} />
        </Box>
      </FlexWrap>
      <Box
        $width="100%"
        $mdMin={{
          $width: '50%',
        }}
      >
        <FlexCol $margin="auto" $maxWidth="350px">
          <FlexColAlignCenter $gap={tokens.spacing.spacingM}>
            <SVGWrap href="https://www.instagram.com/freeway_camper" target="_blank">
              <IG stroke="#001629" height={42} />
            </SVGWrap>
            <a href="https://www.instagram.com/freeway_camper" target="_blank" rel="noreferrer">
              <Body $size="xlBold">@freeway_camper</Body>
            </a>
            <Body $tAlign="center" $size="l">
              <Trans
                id="page.fwc.homepage.social.text"
                message="Inspiring images and a look at life on the road. Follow us on Instagram."
              />
            </Body>
          </FlexColAlignCenter>

          <Divider
            $my={tokens.spacing.spacingXl}
            $mdMin={{
              $my: tokens.spacing.spacing4Xl,
            }}
            $backgroundColor={tokens.color.border.border}
          />

          <FlexCenterBoth $gap={tokens.spacing.spacingM}>
            <SVGWrap href="https://www.facebook.com/FWCFreewayCamper/" target="_blank">
              <FB stroke="#001629" height={32} />
            </SVGWrap>
            <SVGWrap href="https://www.youtube.com/channel/UCjeTrbXy4wXewWw_kcWvNMA" target="_blank">
              <YT stroke="#001629" height={32} />
            </SVGWrap>
          </FlexCenterBoth>
        </FlexCol>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${resolveThemeValue(tokens.spacing.spacing4Xl)};

  a:hover {
    color: ${resolveThemeValue(tokens.color.text.textLink)};
  }

  ${mediaMdMin} {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }
`;

const StyledImage = styled(Image)`
  object-fit: cover;
  overflow: hidden;
  border-radius: ${resolveThemeValue(tokens.radius.radiusL)};
`;

const SVGWrap = styled.a`
  path.ic_instagram_svg__dot {
    fill: #001629;
  }

  &:hover {
    path,
    svg {
      stroke: ${resolveThemeValue(tokens.color.text.textLink)} !important;
    }

    path.ic_instagram_svg__dot {
      fill: ${resolveThemeValue(tokens.color.text.textLink)};
    }
  }
`;
