import React, { FC, useRef } from 'react';
import Image from 'next/legacy/image';
import { Trans } from '@lingui/react';
import styled, { css } from 'styled-components';
// import HomePageImage from '../../../../public/static/compressedImages/pageHeader/homepage-autumn.png';
// import HomePageImage from '../../../../public/static/compressedImages/pageHeader/homepage-spring-min.png';
import HomePageImage from '../../../../public/static/compressedImages/pageHeader/homepage-spring.png';
import SpainHeroImage from '../../../../public/static/compressedImages/pageHeader/spain_hero.jpg';
import LocalizedLink from '~/src/components/LocalizedLink';
import { Country, routes } from '~/src/utils/routing';
import { useTypedSelector } from '~/src/redux/store';
import { settingsSelector } from '~/src/redux/selectors/settings';
import { deviceMaxWidth, deviceMinWidth } from '~/src/styles/breakpoints';
import SearchBar from 'ui/components/searchBar/SearchBar';
import { popularPlaces, popularSearches } from '~/src/components/search/placesAndSearches';
import { changeDateSelection, goToSearchPageWithResetFilters, removeUselessQueryParams } from '~/src/utils/query';
import Router, { useRouter } from 'next/router';
import { makeQueryForSearchFromHP } from '~/src/components/search/utils';
import { searchInfoSelector } from 'features/redux/selectors/search';
import { mediaMdMax, mediaMdMin, mediaSmMax } from 'ui/utils/breakpoints';
import { useResize } from '~/src/hooks/useResize';

export const HomePageHeader = () => {
  const searchInfo = useTypedSelector(searchInfoSelector);
  const router = useRouter();
  const settings = useTypedSelector(settingsSelector);
  const isOfferBarVisible = settings.specialOfferBarVisible;
  const sectionRef = useRef<HTMLDivElement>(null);
  const { height } = useResize(sectionRef);

  const getQueryForSearch = makeQueryForSearchFromHP(searchInfo, removeUselessQueryParams(router.query), false);
  const clearSearch = () => {
    if (Router.route === '/search') {
      const currentQuery = Router.query;
      delete currentQuery.latitude;
      delete currentQuery.longitude;
      goToSearchPageWithResetFilters(currentQuery);
    }
    if (Router.route.includes('landing')) {
      const currentQuery = Router.query;
      delete currentQuery.latitude;
      delete currentQuery.longitude;
      goToSearchPageWithResetFilters(currentQuery);
    }
  };

  return (
    <SectionWrapper ref={sectionRef}>
      <BgWrap height={height}>
        <Image
          className={`${isOfferBarVisible ? 'homeImageOfferBar' : 'homeImage'}`}
          priority={true}
          alt="Mountains"
          src={settings?.country === Country.es ? SpainHeroImage : HomePageImage}
          layout="fill"
          objectPosition={settings?.country === Country.es ? '50% 70%' : '50% 50%'}
          objectFit="cover"
          quality={60}
        />
      </BgWrap>
      <HomepageHeading>
        <Trans
          id="mainPage.header.heading2"
          components={{ 0: <br /> }}
          message="The easiest way <0/> to rent a campervan"
        />
      </HomepageHeading>

      <HpSubHeading>
        <Trans
          id="mainPage.header.subheading"
          components={{ 0: <LocalizedLink prefetch={false} href={routes.search} passHref={true} /> }}
          message="Vybírejte ze <0>stovek obytných vozů</0>. Cestujte svobodně a udržitelně."
        />
      </HpSubHeading>
      <SearchBar
        dynamicSegmentRoute={routes.dynamicSegmentNew}
        queryForSearch={getQueryForSearch}
        popularSearches={popularSearches}
        onDateChange={changeDateSelection}
        route={routes.search}
        customClearSearchSideEffect={clearSearch}
        popularPlaces={popularPlaces}
      />
    </SectionWrapper>
  );
};

const BgWrap = styled.div<{ height: number }>`
  position: absolute;
  background-color: #1f2244;
  width: 100vw;
  overflow: hidden;
  z-index: -1;
  left: 0px;
  top: 0px;
  user-select: none;
  height: 670px !important;
  max-height: 670px;

  ${mediaMdMax} {
    max-height: ${(props) => props.height + 200}px;
  }

  ${mediaSmMax} {
    max-height: ${(props) => props.height + 40}px;
  }

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(31, 34, 68, 0.6);
  }

  .homeImageOfferBar {
    ${mediaMdMax} {
      top: 80px;
    }
  }

  .homeImage {
    ${mediaMdMax} {
      top: -80px;
    }
  }
`;

const HomepageHeading = styled.h1`
  text-shadow: 0 8px 20px rgba(0, 0, 0, 0.32);
  font-family: var(--font-baloo);
  font-size: 68px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--color-beige);
  align-self: baseline;
  width: 74%;
  user-select: none;

  ${mediaSmMax} {
    line-height: 40px;
    width: 100%;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;

    br {
      display: none;
    }
  }

  @media only screen and (max-width: 450px) {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
  }
`;

const SubHeading = styled.div`
  text-shadow: 0 8px 20px rgba(0, 0, 0, 0.32);
  font-family: var(--font-poppins);

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: var(--color-beige);
  margin-top: 12px;

  > a {
    color: inherit;
    text-decoration: underline;
  }

  @media ${deviceMaxWidth.phone} {
    font-size: 14px;
  }
`;

const HpSubHeading = styled(SubHeading)`
  @media (max-width: 480px) {
    max-width: 250px;
    margin-top: 16px;
  }
`;

const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1040px;
  margin: 0 auto;
  padding: 60px 0px;

  @media ${deviceMaxWidth.tablet} {
    padding: 16px;
  }

  @media ${deviceMaxWidth.phone} {
    padding: 16px;
    overflow: clip;
    overflow-y: visible;
  }
`;
