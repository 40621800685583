import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import dynamic from 'next/dynamic';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { PrimaryButton } from '~/src/styles/buttons';
import { HomepageSection, SectionHead, SectionHeaderWrapper, SectionPreHead } from './styles';
import { Country, Language } from '~/src/utils/routing';
import { settingsSelector } from '~/src/redux/selectors/settings';
import { useTypedSelector } from '~/src/redux/store';

const HowToStartBlockCampiriCover = dynamic(() => import('~/src/components/homepage/HowToStartBlockCampiriCover'), {
  ssr: true,
});
const HowToStartBlockENCZ = dynamic(() => import('~/src/components/homepage/HowToStartBlockENCZ'), { ssr: true });

const HowToStart = () => {
  const settings = useTypedSelector(settingsSelector);

  return (
    <Wrapper>
      <SectionHeaderWrapper>
        <SectionPreHead>
          <Trans id="mainPage.howToStart.sectionPreHead" message="Jak začít" />
        </SectionPreHead>
        <SectionHead>
          <Trans id="mainPage.howToStart.sectionHead.fewStepsToYourCaravan" message="Pár kroků k vašemu karavanu" />
        </SectionHead>
      </SectionHeaderWrapper>
      <FindTeaser>
        <FindTeaserBox>
          <TeaserBoxCirlcle>1</TeaserBoxCirlcle>
          <TeaserBoxHeading>
            <TeaserBoxCirlcle>1</TeaserBoxCirlcle>
            <Trans id="mainPage.howToStart.teaserBoxHeading1" message="Kdy pojedete?" />
          </TeaserBoxHeading>
          <TeaserBoxContent>
            <Trans
              id="mainPage.howToStart.teaserBoxContent1"
              message="Zvolte si místo odjezdu, datum a počet lidí, kteří se zúčastní parádního road-tripu."
            />
          </TeaserBoxContent>
        </FindTeaserBox>
        <FindTeaserBox>
          <TeaserBoxCirlcle>2</TeaserBoxCirlcle>
          <TeaserBoxHeading>
            <TeaserBoxCirlcle>2</TeaserBoxCirlcle>
            <Trans id="mainPage.howToStart.teaserBoxHeading2" message="Čím pojedete?" />
          </TeaserBoxHeading>
          <TeaserBoxContent>
            <Trans
              id="mainPage.howToStart.teaserBoxContent2"
              message="Vyberte si z dostupné nabídky vhodný karavan. Anebo se obraťte na nás a my Vám pomůžeme."
            />
          </TeaserBoxContent>
        </FindTeaserBox>
        <FindTeaserBox>
          <TeaserBoxCirlcle>3</TeaserBoxCirlcle>
          <TeaserBoxHeading>
            <TeaserBoxCirlcle>3</TeaserBoxCirlcle>
            <Trans id="mainPage.howToStart.teaserBoxHeading3" message="Vyzvedněte si karavan" />
          </TeaserBoxHeading>
          <TeaserBoxContent>
            <Trans
              id="mainPage.howToStart.teaserBoxContent3"
              message="Propojíme vás s majitelem, se kterým si domluvíte čas a místo předání."
            />
          </TeaserBoxContent>
        </FindTeaserBox>
        <FindTeaserBox>
          <TeaserBoxCirlcle>4</TeaserBoxCirlcle>
          <TeaserBoxHeading>
            <TeaserBoxCirlcle>4</TeaserBoxCirlcle>
            <Trans id="mainPage.howToStart.teaserBoxHeading4" message="Vyrazte na cestu" />
          </TeaserBoxHeading>
          <TeaserBoxContent>
            <Trans
              id="mainPage.howToStart.teaserBoxContent4"
              message="Majitel s vámi vyřídí formality, zaškolí vás do ovládání karavanu a dobrodružství může začít."
            />
          </TeaserBoxContent>
        </FindTeaserBox>
        <FindTeaserBox>
          <TeaserBoxCirlcle>5</TeaserBoxCirlcle>
          <TeaserBoxHeading>
            <TeaserBoxCirlcle>5</TeaserBoxCirlcle>
            <Trans id="mainPage.howToStart.teaserBoxHeading5" message="Vraťte karavan majiteli" />
          </TeaserBoxHeading>
          <TeaserBoxContent>
            <Trans
              id="mainPage.howToStart.teaserBoxContent5"
              message="Užili jste si cestu? Tak teď už jen vrátit obytku ve stavu, v jakém jste ji dostali."
            />
          </TeaserBoxContent>
        </FindTeaserBox>
      </FindTeaser>
      <FindCaravanButton
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}>
        <Trans id="mainPage.howToStart.findCaravan" message="Najít karavan" />
      </FindCaravanButton>

      {settings.country === Country.cz && settings.language !== Language.cs && <HowToStartBlockENCZ />}
      {settings.country === Country.cz && settings.language === Language.cs && <HowToStartBlockCampiriCover />}
    </Wrapper>
  );
};

const Wrapper = styled(HomepageSection)`
  background-image: url('/static/images/vectors/trees.svg');
  background-repeat: no-repeat;
  background-position: 100% 50px;
  border-bottom: 1px solid var(--color-beige);
  button {
    max-width: 250px;
    width: fit-content;
    margin: 32px 0 48px;
    @media ${deviceMaxWidth.phone} {
      max-width: initial;
      margin: 8px 0 32px;
    }
  }
`;

const FindTeaser = styled.div`
  display: flex;
  width: 100%;
  margin-top: 44px;
  position: relative;
  > div > span {
    @media ${deviceMaxWidth.phone} {
      display: none;
    }
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 18%;
    height: 1px;
    top: 13px;
    border-top: dashed 2px var(--color-grey);
    @media ${deviceMaxWidth.phone} {
      height: 0;
      border: medium none;
    }
  }
  @media ${deviceMaxWidth.phone} {
    flex-direction: column;
    margin-top: 4px;
  }
`;

const FindTeaserBox = styled.div`
  flex-grow: 1;
  height: auto;
  min-width: 20.5769231%;
  padding-right: 20px;
`;

const TeaserBoxCirlcle = styled.span`
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 33px;
  border-radius: 14px;
  background-color: #1f2244;
  font-family: var(--font-baloo);
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-white);
  position: relative;
  z-index: 2;
`;

const TeaserBoxHeading = styled.h3`
  margin: 16px 0 12px;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1f2244;
  > span {
    display: none;
    @media ${deviceMaxWidth.phone} {
      display: inline-block;
      margin-right: 12px;
    }
  }
  @media ${deviceMaxWidth.phone} {
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
  }
`;

const TeaserBoxContent = styled.p`
  margin: 12px 0 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1f2244;
  max-width: 182px;
  @media ${deviceMaxWidth.phone} {
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    margin-bottom: 24px;
  }
`;

const FindCaravanButton = styled(PrimaryButton)``;

const EarnBox = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
  @media ${deviceMaxWidth.tablet} {
    height: auto;
    flex-direction: column;
  }
`;

const EarnBoxImageWrapper = styled.div`
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  overflow: hidden;
  position: relative;
  width: 53.8461538%;
  position: relative;
  @media ${deviceMaxWidth.tablet} {
    width: 100%;
    height: 300px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  @media ${deviceMaxWidth.xs} {
    height: 176px;
  }
`;

const EarnBoxContent = styled.div`
  width: 46.1538462%;
  padding: 32px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  background-color: var(--color-beige);
  @media ${deviceMaxWidth.tablet} {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  @media ${deviceMaxWidth.xs} {
    padding: 20px 12px 20px 12px;
  }
`;

const EarnBoxPreHead = styled.span`
  display: inline-block;
  margin: 0 0 12px 0;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1.5px;
  color: var(--color-orange);
  text-transform: uppercase;
  @media ${deviceMaxWidth.xs} {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
  }
`;

const EarnBoxHead = styled.h3`
  margin: 0 0 20px 0;
  font-family: var(--font-poppins);
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1f2244;
  max-width: 414px;
  @media ${deviceMaxWidth.xs} {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 18px;
  }
`;

const EarnListBenefits = styled.ul`
  list-style: none;
  columns: 2;
  margin-bottom: 18px;
  @media ${deviceMaxWidth.xs} {
    columns: 1;
    margin-bottom: 12px;
  }
`;

const EarnListBenefitsItem = styled.li`
  margin: 0 0 12px 0;
  font-family: var(--font-poppins);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1f2244;
  display: flex;
  align-items: center;
  .icon-check {
    @media ${deviceMaxWidth.xs} {
      font-size: 1.2rem;
    }
    margin-right: 12px;
    &:before {
      color: #119383;
    }
  }
`;

export default HowToStart;
